<template>
  <footer class="footer">
    <div class="footerDescr">
      <p><small>Organisations I've worked with</small></p>
    </div>
    <div class="logo-slider-wrapper">
      <div
        class="logo-slider"
        :style="{ transform: `translateX(${-offset}px)` }"
      >
        <!-- Duplicate the logos once -->
        <img
          v-for="(logo, index) in logos.concat(logos, logos, logos)"
          :key="`logo-${index}`"
          :src="logo.src"
          :alt="logo.alt"
          class="logo"
        />
      </div>
    </div>
  </footer>
</template>
<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "LogoSlider",
  setup() {
    const logos = ref([
      { src: require("@/assets/images/Logos/Clients/hsbc.png"), alt: "HSBC" },

      {
        src: require("@/assets/images/Logos/Clients/paragon_bank.png"),
        alt: "Paragon Bank",
      },
      {
        src: require("@/assets/images/Logos/Clients/barclays.png"),
        alt: "Barclays",
      },
      {
        src: require("@/assets/images/Logos/Clients/camden.png"),
        alt: "Camden Council",
      },
      { src: require("@/assets/images/Logos/Clients/csm.png"), alt: "CSM" },
      {
        src: require("@/assets/images/Logos/Clients/redbull.png"),
        alt: "Redbull",
      },
      {
        src: require("@/assets/images/Logos/Clients/ey.png"),
        alt: "Ernst & Young",
      },

      { src: require("@/assets/images/Logos/Clients/fcg.png"), alt: "FCG" },
      {
        src: require("@/assets/images/Logos/Clients/circular.png"),
        alt: "Circular",
      },
      {
        src: require("@/assets/images/Logos/Clients/filhounico.png"),
        alt: "Filho Único",
      },

      { src: require("@/assets/images/Logos/Clients/bial.png"), alt: "Bial" },
      {
        src: require("@/assets/images/Logos/Clients/genomics_england.png"),
        alt: "Genomics England",
      },
      {
        src: require("@/assets/images/Logos/Clients/jm_vert.png"),
        alt: "JM Vertical",
      },
      { src: require("@/assets/images/Logos/Clients/metro.png"), alt: "Metro" },

      { src: require("@/assets/images/Logos/Clients/porto.png"), alt: "Porto" },
    ]);

    const offset = ref(0);
    let intervalId = null;

    onMounted(() => {
      const speed = 0.5; // pixels per frame
      const fps = 60; // frames per second
      const totalLogos = logos.value.length * 4; // total logos including duplicates
      const logoWidthIncludingMargin = 160; // logo width plus margin
      const totalWidth = totalLogos * logoWidthIncludingMargin;

      intervalId = setInterval(() => {
        offset.value += speed;
        if (offset.value >= totalWidth / 2) {
          offset.value = 0; // reset offset to start when half is scrolled
        }
      }, 1000 / fps);
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    return {
      logos,
      offset,
    };
  },
};
</script>

<style scoped>
.footer {
  position: relative;
  transform: translateX(calc(var(--regularMargin) * -1));
  width: 100vw;
  padding: var(--smallerMargin) 0 var(--smallMargin) 0;
  z-index: 1;
  opacity: 0.4;
}

.logo-slider-wrapper {
  overflow: hidden;
  max-height: 50px;
  position: relative;
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 95%,
    rgba(255, 255, 255, 0)
  );
}
.footerDescr {
  margin-left: var(--regularMargin);
}

.logo-slider {
  display: flex;
}

.logo {
  display: block;
  width: 120px;
  max-height: 45px;
  margin-right: 38px;
  object-fit: contain;
  opacity: 0.6;
  border-radius: 0px;
}

@media (max-width: 768px) {
  .logo {
    width: 110px; /* Reduced width for smaller screens */
    margin-right: 25px;
    max-height: 38px;
  }
}
</style>
