<template>
  <transition name="fade">
    <div class="intro readingWidth">
      <div>
        <h2>
          <span v-if="!isWideScreen">Hello! </span>My name is João Marrucho, a
          designer and programmer with two decades of multi-disciplinary
          experience shaping new visions.
        </h2>
        <h2>
          I am a founding partner of
          <a href="https://www.patio.consulting">Patio</a>, a design firm
          specialised in technology, and strategy solutions. I’ve worked with a
          wide range of established organisations and emerging household names.
        </h2>
        <h2>
          I thrive working with curious, driven, and good-humored people. If
          that sounds like you, we're already on the same page. Let's get things
          started!
        </h2>
      </div>
      <button class="noSideMargins" @click="sendEmail">Get in touch</button>
    </div>
  </transition>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import LogoSlider from "@/components/GenericComponents/LogoSlider.vue";

export default {
  name: "HelloWorld",
  components: {
    LogoSlider,
  },
  props: {
    isWideScreen: Boolean,
  },
  setup() {
    const store = useStore();
    const sendEmail = () => {
      const mailtoLink = `mailto:joaoalvesmarrucho@gmail.com?subject=Contact%20from%20Website&body=${encodeURIComponent(
        "Hello João, \n\n(let me know why you're getting in touch here)"
      )}`;
      window.open(mailtoLink, "_blank");
    };
    return { sendEmail };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
